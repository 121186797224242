// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'

export const getAllData = createAsyncThunk('appCoaches/getAllData', async () => {
  const response = await axios.get('/api/coach?page=1&perPage=20')
  return response.data.data
})

export const getData = createAsyncThunk('appCoaches/getData', async params => {
  const response = await axios.get('/api/coach', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getCoach = createAsyncThunk('appCoaches/getCoach', async id => {
  const response = await axios.get('/api/coach', { id })
  return response.data.coach
})

export const addCoach = createAsyncThunk('appCoaches/addCoach', async (coach, { dispatch, getState }) => {
  await axios.post('/apps/coaches/add-coach', coach)
  await dispatch(getData(getState().coaches.params))
  await dispatch(getAllData())
  return coach
})

export const deleteCoach = createAsyncThunk('appCoaches/deleteCoach', async (id, { dispatch, getState }) => {
  await axios.delete('/api/coach', { id })
  await dispatch(getData(getState().coaches.params))
  await dispatch(getAllData())
  return id
})

export const appCoachesSlice = createSlice({
  name: 'appCoaches',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCoach: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCoach.fulfilled, (state, action) => {
        state.selectedCoach = action.payload
      })
  }
})

export default appCoachesSlice.reducer

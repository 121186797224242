// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'


export const getStats = createAsyncThunk('app/getStats', async () => {
  const response = await axios.get('/api/stats')
  const {data} = response
  return {
    data
  }
})


export const appSlice = createSlice({
  name: 'app',
  initialState: {
    data: [],
    stats: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStats.fulfilled, (state, action) => {
        state.stats = action.payload.data
      })
  }
})

export default appSlice.reducer

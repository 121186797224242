// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'

export const getAllData = createAsyncThunk('appAccounts/getAllData', async () => {
  const response = await axios.get('/api/account?page=1&perPage=20')
  return response.data.data
})

export const getData = createAsyncThunk('appAccounts/getData', async params => {
  const response = await axios.get('/api/account', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getAccount = createAsyncThunk('appAccounts/getAccount', async id => {
  const response = await axios.get('/api/account', { id })
  return response.data.account
})

export const addAccount = createAsyncThunk('appAccounts/addAccount', async (account, { dispatch, getState }) => {
  await axios.post('/api/account', account)
  await dispatch(getData(getState().accounts.params))
  await dispatch(getAllData())
  return account
})

export const deleteAccount = createAsyncThunk('appAccounts/deleteAccount', async (id, { dispatch, getState }) => {
  await axios.delete('/api/account', { id })
  await dispatch(getData(getState().accounts.params))
  await dispatch(getAllData())
  return id
})

export const printReceipt = createAsyncThunk('appAccounts/printReceipt', async (id, { dispatch, getState }) => {
  const response = await axios.get(`/api/account/${id}/receipt`,{
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/pdf'
    }
  })
  return response
})

export const acceptPayment = createAsyncThunk('appAccounts/acceptPayment', async (id, { dispatch, getState }) => {
  const response = await axios.put(`/api/account/${id}/subscription/renew`, { account_id: id})
  await dispatch(getData(getState().accounts.params))
  await dispatch(getAllData())
  return response
})

export const appAccountsSlice = createSlice({
  name: 'appAccounts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAccount: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        state.selectedAccount = action.payload
      })
  }
})

export default appAccountsSlice.reducer

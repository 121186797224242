// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'

export const getAllData = createAsyncThunk('appFields/getAllData', async () => {
  const response = await axios.get('/api/field?page=1&perPage=20')
  return response.data.data
})

export const getData = createAsyncThunk('appFields/getData', async params => {
  const response = await axios.get('/api/field', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getField = createAsyncThunk('appFields/getField', async id => {
  const response = await axios.get('/api/field', { id })
  return response.data.field
})

export const addField = createAsyncThunk('appFields/addField', async (field, { dispatch, getState }) => {
  await axios.post('/apps/fields/add-field', field)
  await dispatch(getData(getState().fields.params))
  await dispatch(getAllData())
  return field
})

export const deleteField = createAsyncThunk('appFields/deleteField', async (id, { dispatch, getState }) => {
  await axios.delete('/api/field', { id })
  await dispatch(getData(getState().fields.params))
  await dispatch(getAllData())
  return id
})

export const appFieldsSlice = createSlice({
  name: 'appFields',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedField: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getField.fulfilled, (state, action) => {
        state.selectedField = action.payload
      })
  }
})

export default appFieldsSlice.reducer

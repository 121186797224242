// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'

export const getAllData = createAsyncThunk('appSessions/getAllData', async () => {
  const response = await axios.get('/api/session?page=1&perPage=20')
  return response.data.data
})

export const getData = createAsyncThunk('appSessions/getData', async params => {
  const response = await axios.get('/api/session', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getSession = createAsyncThunk('appSessions/getSession', async id => {
  const response = await axios.get('/api/session', { id })
  return response.data.session
})

export const addSession = createAsyncThunk('appSessions/addSession', async (session, { dispatch, getState }) => {
  await axios.post('/apps/sessions/add-session', session)
  await dispatch(getData(getState().sessions.params))
  await dispatch(getAllData())
  return session
})

export const deleteSession = createAsyncThunk('appSessions/deleteSession', async (id, { dispatch, getState }) => {
  await axios.delete('/api/session', { id })
  await dispatch(getData(getState().sessions.params))
  await dispatch(getAllData())
  return id
})

export const appSessionsSlice = createSlice({
  name: 'appSessions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedSession: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.selectedSession = action.payload
      })
  }
})

export default appSessionsSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'

export const getAllData = createAsyncThunk('appPlayers/getAllData', async () => {
  const response = await axios.get('/api/player?page=1&perPage=20')
  return response.data.data
})

export const getData = createAsyncThunk('appPlayers/getData', async params => {
  const response = await axios.get('/api/player', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getPlayer = createAsyncThunk('appPlayers/getPlayer', async id => {
  const response = await axios.get(`/api/player/${ id }`)
  return response.data.data
})

export const addPlayer = createAsyncThunk('appPlayers/addPlayer', async (player, { dispatch, getState }) => {
  await axios.post('/api/player', player)
  await dispatch(getData(getState().players.params))
  await dispatch(getAllData())
  return player
})

export const deletePlayer = createAsyncThunk('appPlayers/deletePlayer', async (id, { dispatch, getState }) => {
  await axios.delete('/api/player', { id })
  await dispatch(getData(getState().players.params))
  await dispatch(getAllData())
  return id
})
export const printRegistrationForm = createAsyncThunk('appPlayers/printRegistrationForm', async (id, { dispatch, getState }) => {
  const response = await axios.get(`/api/player/${id}/registration-form`,{
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/pdf'
    }
  })
  return response
})

export const appPlayersSlice = createSlice({
  name: 'appPlayers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPlayer: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPlayer.fulfilled, (state, action) => {
        state.selectedPlayer = action.payload
      })
  }
})

export default appPlayersSlice.reducer

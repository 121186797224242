// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'

export const getAllData = createAsyncThunk('appTrainingGroups/getAllData', async () => {
  const response = await axios.get('/api/training-group?page=1&perPage=20')
  return response.data.data
})

export const getData = createAsyncThunk('appTrainingGroups/getData', async params => {
  const response = await axios.get('/api/training-group', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getTrainingGroup = createAsyncThunk('appTrainingGroups/getTrainingGroup', async id => {
  const response = await axios.get('/api/training-group', { id })
  return response.data.trainingGroup
})

export const addTrainingGroup = createAsyncThunk('appTrainingGroups/addTrainingGroup', async (trainingGroup, { dispatch, getState }) => {
  await axios.post('api/training-group', trainingGroup)
  await dispatch(getData(getState().trainingGroups.params))
  await dispatch(getAllData())
  return trainingGroup
})

export const deleteTrainingGroup = createAsyncThunk('appTrainingGroups/deleteTrainingGroup', async (id, { dispatch, getState }) => {
  await axios.delete('/api/training-group', { id })
  await dispatch(getData(getState().trainingGroups.params))
  await dispatch(getAllData())
  return id
})

export const appTrainingGroupsSlice = createSlice({
  name: 'appTrainingGroups',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTrainingGroup: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTrainingGroup.fulfilled, (state, action) => {
        state.selectedTrainingGroup = action.payload
      })
  }
})

export default appTrainingGroupsSlice.reducer

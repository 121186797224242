// ** Redux Imports
import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import {  getStats } from '@views/store'

// ** Axios Imports
import axios from '@utility/axios'

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async calendars => {
  const response = await axios.get('/api/session', { calendars })
  return response.data
})

export const addTraining = createAsyncThunk('appCalendar/addTraining', async (event, { dispatch, getState }) => {
  await axios.post('/api/training',  event)
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  await dispatch(getStats())
  return event
})
export const updateSession = createAsyncThunk('appCalendar/updateSession', async (event, { dispatch, getState }) => {
  await axios.put(`/api/session/${event.id}`,  event.obj)
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  await dispatch(getStats())
  return event
})
export const removeSession = createAsyncThunk('appCalendar/removeSession', async id => {
  await axios.delete(`/api/session/${id}`, { id })
  return id
})
export const updateTraining = createAsyncThunk('appCalendar/updateTraining', async (event, { dispatch, getState }) => {
  await axios.put(`/api/training/${event.id}`,  event.obj)
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  await dispatch(getStats())
  return event
})
export const updateReservation = createAsyncThunk('appCalendar/updateReservation', async (event, { dispatch, getState }) => {
  await axios.put(`/api/reservation/${event.id}`,  event.obj)
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  await dispatch(getStats())
  return event
})
export const addReservation = createAsyncThunk('appCalendar/addReservation', async (event, { dispatch, getState, rejectWithValue }) => {
  try {
    await axios.post('/api/reservation', event)
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  await dispatch(getStats())
  return event
  } catch (error) {
    if (!error.response) {
      throw error
    }
    
    return rejectWithValue(error.response.data)
  }
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, { dispatch, getState }) => {
  console.log('event', event)
  // await axios.post('/apps/calendar/update-event', { event })
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  return event
})

export const updateFilter = createAsyncThunk('appCalendar/updateFilter', async (filter, { dispatch, getState }) => {
  if (getState().calendar.selectedCalendars.includes(filter)) {
    await dispatch(fetchEvents(getState().calendar.selectedCalendars.filter(i => i !== filter)))
  } else {
    await dispatch(fetchEvents([...getState().calendar.selectedCalendars, filter]))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, { dispatch }) => {
  if (value === true) {
    await dispatch(fetchEvents(['Personal', 'Business', 'Family', 'Holiday', 'ETC']))
  } else {
    await dispatch(fetchEvents([]))
  }
  return value
})

export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async id => {
  await axios.delete('/apps/calendar/remove-event', { id })
  return id
})

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: ['reservation', 'training']
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
        } else {
          state.selectedCalendars.push(action.payload)
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
        } else {
          selected = []
        }
        state.selectedCalendars = selected
      })
      .addCase(addReservation.fulfilled, (state, action) => {
        console.log(state, action)
      })
      .addCase(addReservation.rejected, (state, action) => {
        console.log(state, action)
      })
  }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer

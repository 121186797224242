// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './authentication'

import users from '@src/views/modules/user/store'
import fields from '@src/views/modules/field/store'
import categories from '@src/views/modules/category/store'
import trainingGroups from '@src/views/modules/trainingGroup/store'
import accounts from '@src/views/modules/account/store'
import sessions from '@src/views/modules/session/store'
import coaches from '@src/views/modules/coach/store'
import players from '@src/views/modules/player/store'
import trainings from '@src/views/modules/training/store'
import calendar from '@src/views/modules/calendar/store'
import app from '@src/views/store'
const rootReducer = { navbar, layout, auth, 
    users,
    fields,
    categories,
    trainingGroups,
    accounts,
    sessions,
    coaches,
    players,
    trainings,
    calendar,
    app
 }

export default rootReducer

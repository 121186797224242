// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@utility/axios'

export const getAllData = createAsyncThunk('appTrainings/getAllData', async () => {
  const response = await axios.get('/api/training?page=1&perPage=20')
  return response.data.data
})

export const getData = createAsyncThunk('appTrainings/getData', async params => {
  const response = await axios.get('/api/training', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getTraining = createAsyncThunk('appTrainings/getTraining', async id => {
  const response = await axios.get('/api/training', { id })
  return response.data.training
})

export const addTraining = createAsyncThunk('appTrainings/addTraining', async (training, { dispatch, getState }) => {
  await axios.post('/apps/trainings/add-training', training)
  await dispatch(getData(getState().trainings.params))
  await dispatch(getAllData())
  return training
})

export const deleteTraining = createAsyncThunk('appTrainings/deleteTraining', async (id, { dispatch, getState }) => {
  await axios.delete('/api/training', { id })
  await dispatch(getData(getState().trainings.params))
  await dispatch(getAllData())
  return id
})

export const appTrainingsSlice = createSlice({
  name: 'appTrainings',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTraining: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTraining.fulfilled, (state, action) => {
        state.selectedTraining = action.payload
      })
  }
})

export default appTrainingsSlice.reducer

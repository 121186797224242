// axios
import baseAxios from 'axios'
import jwtDefaultConfig from "@core/auth/jwt/jwtDefaultConfig";

const jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  let isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
let subscribers = [];

let axios = baseAxios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://127.0.0.1:8000/',
  baseURL: process.env.NODE_ENV == 'production'? 'https://api.univers-sport.tn/':'http://localhost:8000/',
  headers: {'Accept': 'application/json'}
})

axios.defaults.withCredentials = true;
// ** Request Interceptor
axios.interceptors.request.use(
  (config) => {
    // ** Get token from localStorage
    const accessToken = getToken();

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// ** Add request/response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // ** const { config, response: { status } } = error
    const { config, response } = error;
    const originalRequest = config;

    // ** if (status === 401) {
    if (response && response.status === 401) {
      window.location.location = '/login';
      Promise.reject(response);
      // if (!isAlreadyFetchingAccessToken) {
      //   isAlreadyFetchingAccessToken = true;
        // refreshToken().then((r) => {
        //   isAlreadyFetchingAccessToken = false;

        //   // ** Update accessToken in localStorage
        //   setToken(r.data.accessToken);
        //   setRefreshToken(r.data.refreshToken);

        //   onAccessTokenFetched(r.data.accessToken);
        // });
      //}
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          // ** Make sure to assign accessToken according to your response.
          // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          // ** Change Authorization header
          originalRequest.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken)
  );
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function getToken() {
  return localStorage.getItem(jwtConfig.storageTokenKeyName);
}

function setToken(value) {
  localStorage.setItem(jwtConfig.storageTokenKeyName, value);
}

function setRefreshToken(value) {
  localStorage.setItem(jwtConfig.storageRefreshTokenKeyName, value);
}

function getRefreshToken() {
  return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
}

function refreshToken() {
  return axios.post(jwtConfig.refreshEndpoint, {
    refreshToken: getRefreshToken()
  });
}

export default axios;
